<template>
  <v-app-bar color="primary" dark app class="no-print">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-app-bar-nav-icon @click="handleDrawerToggle" v-on="on" />
      </template>
      <span>{{ $t('menu_toggle') }}</span>
    </v-tooltip>
    <v-spacer />
    <v-toolbar-items>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon @click="handleFullScreen()" v-on="on">
            <v-icon>fullscreen</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('fullscreen') }}</span>
      </v-tooltip>
      <v-menu offset-y origin="center center" transition="scale-transition">
        <template #activator="{ on }">
          <v-btn slot="activator" icon large text v-on="on">
            <c-avatar :size="36" :username="$store.state.auth.login.name" status="online" />
          </v-btn>
        </template>
        <v-list class="pa-0" style="min-width: 11em">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :key="index"
            :to="!item.href && item.name ? { name: item.name } : null"
            :href="item.href"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            @click="item.click"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menu.' + item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-toolbar v-if="extended" slot="extension" tag="div" dense :light="$store.getters.getThemeMode != 'dark'">
      <v-icon>home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-3" />
      <v-spacer />
      <v-btn icon small>
        <v-icon @click="handleGoBack"> fa-light fa-arrow-left-long </v-icon>
      </v-btn>
    </v-toolbar>
  </v-app-bar>
</template>
<script>
import CAvatar from '@/components/avatar/CAvatar'
import Util from '@/util'

export default {
  name: 'AppToolbar',
  components: {
    CAvatar,
  },
  props: {
    extended: Boolean,
  },
  data() {
    return {
      profileMenus: [
        {
          icon: 'account_circle',
          title: 'user_info',
          click: this.handleProfile,
        },
        {
          icon: 'pin',
          title: 'user_otp',
          click: this.handleOtp,
        },
        {
          icon: 'fa-tint',
          title: 'theme_setting',
          click: this.handleThemeSettings,
        },
        {
          icon: 'fa-sign-out',
          name: 'Logout',
          title: 'auth_logout',
          click: this.handleLogut,
        },
      ],
    }
  },
  computed: {
    breadcrumbs() {
      const { matched } = this.$route
      let prev = null
      return matched
        .map((route, index) => {
          const to =
            index === matched.length - 1 ? this.$route.path : 'path' in route ? route.path || '/' : route.redirect
          const text = this.$t('menu.' + route.meta.title)
          const ignored = to === prev || to === prev + '/'
          prev = to
          if (ignored) {
            return null
          }
          return {
            text: text,
            to: to,
            exact: true,
            disabled: false,
          }
        })
        .filter((route) => route !== null)
    },
  },
  created() {},
  methods: {
    handleDrawerToggle() {
      this.$emit('side-icon-click')
    },

    handleFullScreen() {
      Util.toggleFullScreen()
    },

    handleProfile() {
      this.$router.push('/user/detail/' + this.$store.getters.getLoginUserID)
    },

    handleOtp() {
      this.$router.push('/user/otp/' + this.$store.getters.getLoginUserID)
    },

    handleThemeSettings() {
      this.$emit('theme-settings-click')
    },

    handleLogut() {
      this.$router.push('/auth/logout')
    },

    handleGoBack() {
      this.$router.go(-1)
    },
  },
}
</script>
