<template>
  <v-navigation-drawer
    v-model="showDrawer"
    app
    class="app-drawer no-print"
    :mini-variant.sync="mini"
    mini-variant-width="64"
    :width="drawerWidth"
  >
    <v-toolbar tcolor="primary darken-1">
      <router-link :to="{ name: 'TopIndex' }" class="ml-5">
        <img src="/static/icon/logo.png" alt="Pokest Tools" height="50" contain />
      </router-link>
    </v-toolbar>
    <vue-perfect-scrollbar class="app-drawer__scrollbar">
      <div class="app-drawer__inner">
        <nav-list :items="computeMenu" :mini="mini" />
      </div>
    </vue-perfect-scrollbar>
    <template #append>
      <div class="grey lighten-3">
        <template v-if="mini">
          <v-btn block width="64" height="48" icon tile class="mx-auto" @click="handleDrawerCollapse">
            <v-icon>fa-light fa-arrow-right-long</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn right block height="48" icon tile @click="handleDrawerCollapse">
            <v-icon>fa-light fa-arrow-left-long</v-icon>
          </v-btn>
        </template>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { protectedRoute as routes } from '@/router/config'
import NavList from '@/components/nav/NavList'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'AppDrawer',
  components: { VuePerfectScrollbar, NavList },
  props: {},
  data() {
    return {
      mini: false,
      showDrawer: !this.$vuetify.breakpoint.mobile,
      drawerWidth: 256,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      allowedPaths: [],
    }
  },

  computed: {
    computeLogo() {
      return '/static/m.png'
    },
    computeMenu() {
      return this.filterRouteItem(routes[0].children)
    },
    computeHeight() {
      return {
        height: this.height || '',
      }
    },
  },

  created() {
    this.loadAllowedPaths()
  },

  methods: {
    filterRouteItem(routes) {
      return routes
        .filter((item) => item.meta.hidden !== true && item.path && this.allowedPaths.includes(item.path))
        .map((item) => {
          return {
            title: this.$t('menu.' + item.meta.title),
            icon: item.meta.icon,
            path: item.path,
            isNew: item.meta.isNew || false,
            children: item.children ? this.filterRouteItem(item.children) : [],
          }
        })
    },
    handleDrawerCollapse() {
      this.mini = !this.mini
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer
    },

    async loadAllowedPaths() {
      const res = await this.$axios.get('/api/menu?' + Date.now())
      if (res.data) {
        this.allowedPaths = res.data
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.app-drawer
  &__srollbar
    max-height:  calc(100vh - 64px - 36px - 44px)
  &__inner
    height: calc(100vh - 64px - 36px - 44px)
</style>
